@import "@/styles/variables.scss";

.error {
  color: $wAlert !important;
  label {
    color: $wAlert;
  }
  input,
  textarea {
    border: 1px $wAlert solid !important;
  }
  .field-input {
    border: 1px $wAlert solid !important;
  }
  .multiselect__tags {
    border: 1px $wAlert solid !important;
  }
}
.green {
  input {
    border: 1px $wGreenSucc solid !important;
  }
  .field-input {
    border: 1px $wGreenSucc solid !important;
  }
  .multiselect__tags {
    border: 1px $wGreenSucc solid !important;
  }
}
