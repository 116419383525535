$wGreen: #b0d29f;
$wLightGreen: #e4f3dd;
$wDarkGreen: #2d3d24;
$wAlert: #f44d63;

$wRed: rgb(235, 137, 137);

$wGreyDark: #656565;
$wGreyMedium: #afa6a6;
$wGreyLight: #00000029;
$klausPurple: #3ece62;
$wGreenSucc: #3ece62;
$primary: #b0d29f;
